import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: string): string {
    value = value?.replace(/[^0-9APMapm:_/\s]/g, '');
    value = value?.replace(/[::]/g, ':');
    if (value.length > 8) {
      value = value.substring(0, 8);
    }

    if (/^11:(5[0-9]) pm$/.test(value)) {
      return '11:59 PM';
    } else {
      // Handle hours
      let hours = value.substring(0, 2).replace(/[APMapm:]/, '');

      if (/[0-1_]?[1-9_]/.test(hours)) {
        if (+hours > 24) {
          hours = '12';
        }
        if (+hours > 12 && +hours < 22) {
          hours = '0' + (+hours - 12);
        }
        if (+hours > 21 && +hours < 24) {
          hours = '' + (+hours - 12);
        }
      }

      // Handle minutes
      let minutes = value.substring(3, 6);
      minutes = minutes.replace(':', '');
      minutes = minutes.replace(/[AaPpMm]/, '');
      if (minutes.length > 2) {
        minutes = minutes.substr(0, 2);
      }

      if (/[6-9][0-9]/.test(minutes)) {
        minutes = '00';
        // minutes = round15(minutes) === 0 ? '00 ' : round15(minutes) + '';
      } else if (minutes.length && !/_?[0-9]|[0-9]?_/.test(minutes)) {
        minutes = '__ ';
      }

      // AM/PM handling
      let period = value.substring(5, 9).replace(' ', '').toLowerCase();
      if (period.length === 2 && period !== 'am' && period !== 'pm') {
        if (period.startsWith('a')) {
          period = 'am';
        } else if (period.startsWith('p')) {
          period = 'pm';
        } else {
          period = 'am';
        }
      } else {
        period = period?.replace(/[^APMapm\s]/g, '');
      }
      // Format output
      let formattedTime = hours;
      if (formattedTime.length === 2) {
        formattedTime += ':' + minutes;
      }
      if (period.length) {
        formattedTime += ' ' + period;
      }
      formattedTime = formattedTime?.replace(/[::]/g, ':');
      formattedTime = formattedTime?.replace(/  +/g, ' ');

      return formattedTime;
    }
  }
}
